//document.addEventListener('DOMContentLoaded', function () {

    var necessary = true;
    var analytics = true;
    var preferences = true;
    var marketing = true;
    var functional = true;
    
    function escapeHTML(str) {
      return str.replace(/[&<>"'\/]/g, function (char) {
          
      switch (char) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case '\\':
          return '&#39;';
        case '/':
          return '&#x2F;';
        default:
          return char;
        }
      });
    }
    
    // Скриване или показване на падащото меню 'Научете повече' в главния раздел на банера
    document.querySelector('.fc-faq-header.fc-dialog-restricted-content').addEventListener('click', function () {
        const element = document.querySelector('.fc-faq-header.fc-dialog-restricted-content');
    
        if (element && element.classList.contains('collapsed')) {
            element.classList.toggle('collapsed');
            document.getElementById('cookieCollapseContainer').style.setProperty("display", "flex", "important");
            document.querySelector('.material-icons.faq-section-open').style.setProperty("display", "flex", "important");
            document.querySelector('.material-icons.faq-section-closed').style.setProperty("display", "none", "important");
        } else {;
            element.classList.toggle('collapsed');
            document.getElementById('cookieCollapseContainer').style.setProperty("display", "none", "important");
            document.querySelector('.material-icons.faq-section-open').style.setProperty("display", "none", "important");
            document.querySelector('.material-icons.faq-section-closed').style.setProperty("display", "flex", "important");
        }
    });
    
    // Скриване на банера на бисквитките
    function hideBanner() {
        document.querySelector('.fc-consent-root').style.setProperty("display", "none", "important");
    }
    
    // Изпращане на сигнал за това какво е позволил потребителя относно личните си данни към Google Analytics
    function setConsent(consent) {
        const consentMode = {
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied',
            'ad_user_data': consent.marketing ? 'granted' : 'denied',
            'ad_personalization': consent.preferences ? 'granted' : 'denied',
            'personalization_storage': consent.preferences ? 'granted' : 'denied',
            'functionality_storage': consent.functionality ? 'granted' : 'denied',
            'security_storage': consent.necessary ? 'granted' : 'denied',
            "region": "BG",
        };
        
        // Изпращане на информацията за съгласие към слоя данни
        dataLayer.push({
            'event': 'consent_update',
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied',
            'ad_user_data': consent.marketing ? 'granted' : 'denied',
            'ad_personalization': consent.preferences ? 'granted' : 'denied',
            'personalization_storage': consent.preferences ? 'granted' : 'denied',
            'functionality_storage': consent.functionality ? 'granted' : 'denied',
            'security_storage': consent.necessary ? 'granted' : 'denied',
        });
        
        // Актуализация на настройките за съгласие на Google Analytics
        gtag("consent", "update", consentMode);
       
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
        fetch('/inovex/resources/views/components/appcookies/appcookies-script.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(consentMode),
        })
        .then(response => response.json())
        .then(data => data)
        .catch(error => console.log(error))
    }
    
    
    
    // Смяна на съдържанието на основния контейнер на банера при натискане на различни бутони в долната част:
    
    // Проверяване дали потребителят вече не е взаимодействал с банера
  
    if (localStorage.getItem('consentMode') === null) {
        // Избор на бутона 'Опции за управление':
            document.querySelector('.fc-button.fc-cta-manage-options').addEventListener('click', function () {
            document.querySelector('.fc-dialog.fc-choice-dialog').style.setProperty("display", "none", "important");
            document.querySelector('.fc-dialog.fc-data-preferences-dialog').style.setProperty("display", "flex", "important");
        });
            
        // Избор на бутона 'Получаване на съгласие':
        document.getElementById('btn-consent').addEventListener('click', function () {
            setConsent({
                necessary: true,
                analytics: true,
                preferences: true,
                marketing: true,
                functionality: true,
            });
                
            hideBanner();
        });
            
        // Избор на бутона 'Потвърждаване на избора':
        document.getElementById('btn-accept-some').addEventListener('click', function () {
            setConsent({
                necessary: true,
                analytics: analytics,
                preferences: preferences,
                marketing: marketing,
                functionality: functional, 
            });
                
            hideBanner();
        });
            
        // Избор на бутона 'Приемане на всички':
        document.getElementById('btn-accept-all').addEventListener('click', function () {
            setConsent({
                necessary: true,
                analytics: true,
                preferences: true,
                marketing: true,
                functionality: true,
            });
                
            hideBanner();
        });
            
        document.querySelector('.fc-consent-root').style.setProperty("display", "flex", "important");
    } else {
        document.querySelector('.fc-consent-root').style.setProperty("display", "flex", "important");
    }
    
    
    // Избор на бутона 'Назад':
    document.querySelector('.fc-data-preferences-back').addEventListener('click', function () {
        document.querySelector('.fc-dialog.fc-choice-dialog').style.setProperty("display", "flex", "important");
        document.querySelector('.fc-dialog.fc-data-preferences-dialog').style.setProperty("display", "none", "important");
    });
    
    // Функционалност на избор на отделните опции от потребителя
    var preferenceSliders = document.querySelectorAll('.fc-preference-slider');
    
    preferenceSliders.forEach((slider) => {
          var sliderID = 0;
          var sliderPressed = false;
          
          slider.addEventListener('click', function () {
            if (event.target.dataset.id != undefined) { 
                sliderID = event.target.dataset.id;
                sliderPressed = event.target.checked;
            }
            
            switch(true) {
                case (sliderID === 1 || sliderID === 8 || sliderID === 9 || sliderID === 11):
                {
                    sliderPressed ? analytics = true : analytics = false;
                }
                break;
                case (sliderID === 1 || sliderID === 5 || sliderID === 6):
                {
                    sliderPressed ? preferences = true : preferences = false;
                }
                break;
                case (sliderID === 2 || sliderID === 3 || sliderID === 4 || sliderID === 7):
                {
                    sliderPressed ? marketing = true : marketing = false;
                }
                break;
                case (sliderID === 10):
                {
                    sliderPressed ? functional = true : functional = false;
                }
                break;
            }
        });
    });
    
    // Функционалност за изкачащи прозорци с допълнителна информация при действие от потребителя (натискане на линк или бутон)
    /*document.querySelector('.fc-vendors-list-dialog').addEventListener('click', function () {
        document.querySelector('.fc-help-dialog-container.vendors-dialog').style.setProperty("display", "flex", "important");
    });*/
    
    document.querySelector('.fc-help-dialog-close-button.vendors').addEventListener('click', function () {
        document.querySelector('.fc-help-dialog-container.vendors-dialog').style.setProperty("display", "none", "important");
    });
    
    document.querySelectorAll('a.fc-faq-item').forEach((itemLink) => {
        itemLink.addEventListener('click', function (event) {
            var index = this.dataset.faqItemIndex;
            var title = this.dataset.title;
            var description = this.dataset.description;
            var link = this.dataset.link;
            
            const parser = new DOMParser();
            const descriptionDoc = parser.parseFromString(description, 'text/html');
            const linkDoc = parser.parseFromString(link, 'text/html');
            const descriptionContent = descriptionDoc.body.childNodes;
            const linkContent = linkDoc.body.childNodes;
            
            const descriptionDiv = document.createElement("div");
            const linkDiv = document.createElement("p");
            
            if (descriptionContent) { 
                descriptionContent.forEach(child => {
                    descriptionDiv.appendChild(child.cloneNode(true));
                });
            }
         
            if (linkContent) { 
                linkContent.forEach(child => {
                    linkDiv.appendChild(child.cloneNode(true));
                });
            }
      
            document.querySelector('.fc-help-dialog-container.info-dialog .fc-help-dialog h1').innerText = title;
            document.querySelector('.fc-help-dialog-container.info-dialog .fc-help-dialog .fc-help-dialog-contents').appendChild(descriptionDiv);
            document.querySelector('.fc-help-dialog-container.info-dialog .fc-help-dialog .fc-help-dialog-contents').appendChild(linkDiv);
            document.querySelector('.fc-help-dialog-container.info-dialog').style.setProperty("display", "flex", "important");
        });
    });
    
    document.querySelector('.fc-help-dialog-close-button').addEventListener('click', function () {
        document.querySelector('.fc-help-dialog-container.info-dialog').style.setProperty("display", "none", "important");
        document.querySelector('.fc-help-dialog-container.info-dialog .fc-help-dialog h1').innerHTML = '';
        document.querySelector('.fc-help-dialog-container.info-dialog .fc-help-dialog .fc-help-dialog-contents').innerHTML = '';
    });
    
    //Слушател на събития за автоматично даване на съгласие от потребителя при натискане на клавиша "Enter"
    document.addEventListener('keydown', function (event) {
        if (event.key === "Enter") {
            setConsent({
                necessary: true,
                analytics: true,
                preferences: true,
                marketing: true,
                functionality: true,
            });
            
            hideBanner();
        }
    });
    
//});

window.escapeHTML = escapeHTML;